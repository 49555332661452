import React from "react";
import { IdentificationIcon } from "@heroicons/react/solid";
import List from "../components/List";

const PythonLogo = require("../assets/logos/PythonLogo.png");
const ReactLogo = require("../assets/logos/ReactLogo.png");
const TypescriptLogo = require("../assets/logos/TypescriptLogo.png");
const JsLogo = require("../assets/logos/JsLogo.png");
const phpLogo = require("../assets/logos/phpLogo.png");
const TailwindLogo = require("../assets/logos/TailwindLogo.png");
const SqlLogo = require("../assets/logos/SqlLogo.png");
const ReduxLogo = require("../assets/logos/ReduxLogo.png");
const CLogo = require("../assets/logos/CLogo.png");
const mysqlLogo = require("../assets/logos/mysqlLogo.png");
const NodeJSLogo = require("../assets/logos/NodeJSLogo.png");
const DockerLogo = require("../assets/logos/DockerLogo.png");

const skills = [
  { name: "JavaScript", logo: JsLogo },
  // { name: "Next.js", logo: NextLogo },
  { name: "React", logo: ReactLogo },
  // { name: "TypeScript", logo: TypescriptLogo },
  { name: "PHP", logo: phpLogo },
  { name: "Redux", logo: ReduxLogo },
  { name: "Python", logo: PythonLogo },
  // { name: "Firebase", logo: FirebaseLogo },
  { name: "C", logo: CLogo },
  { name: "Nodejs", logo: NodeJSLogo },
  { name: "SQL", logo: SqlLogo },
  { name: "MySQL", logo: mysqlLogo },
  { name: "Docker", logo: DockerLogo },
];

const About = () => {
  return (
    <div
      id="About"
      className="flex mx-12 mt-60 lg:mx-60 items-center justify-center lg:w-1/2 text-justify"
    >
      <div>
        <div className="table">
          <IdentificationIcon className="h-5 w-5 mr-4 text-yellow_vs" />{" "}
          <code className="table-cell text-[#e6f1ff] text-3xl mt-5 whitespace-nowrap">
            About Me
          </code>
          <div className="table-cell border-b border-b-[#e6f1ff] border-opacity-25 w-full"></div>
        </div>
        <div className="text-[#a2aabc] text-lg mt-5">
          <code>
            I am Charles de Bettignies, a Bachelor of Global Engineering student at 
            CentraleSupélec and McGill University. Passionate about technology and innovation, 
            I have developed a strong expertise in full-stack web development, IT project 
            management, and server administration.
          </code>
          <br />
          <br />
          <code>
            As VP IT at BDB CentraleSupélec, I lead the development of the association’s 
            website and member platform, while ensuring a secure and stable IT infrastructure. 
            Additionally, I have volunteered at Emmaüs Bougival, supporting IT operations and 
            empowering team members with digital skills.
          </code>
          <br />
          <br />
          <code>Key skills and technologies I work with:</code>
          <List list={skills} />
        </div>
      </div>
    </div>
  );
};

export default About;