import React from "react";
import { ClipboardListIcon } from "@heroicons/react/solid";

const Resume = () => {
  return (
    <div
      id="Resume"
      className="lg:w-1/2 mt-64 mb-4 text-justify mx-12 lg:mr-60 lg:ml-auto"
    >
      <div>
        <div className="table">
          <ClipboardListIcon className="h-5 w-5 mr-4 text-yellow_vs" />{" "}
          <code className="table-cell text-[#e6f1ff] text-3xl mt-5 whitespace-nowrap">
            Resume
          </code>
          <div className="table-cell border-b border-b-[#e6f1ff] border-opacity-25 w-full"></div>
        </div>
      </div>
      <div className="text-[#a2aabc] text-lg mt-5">
        <div className="flex flex-row">
          <div className="w-1/4">
            <code className="text-yellow_vs">Education</code>
          </div>
          <div className="w-3/4">
            <code className="text-blue_vs">CentraleSupélec</code>
            <br />
            <code className="italic text-sm text-lightblue_vs">
              Bachelor of Global Engineering
            </code>
            <br />
            <code className="text-xs text-brown_vs">• 2023 - 2027</code>
            <br />
            <code className="text-blue_vs">McGill University</code>
            <br />
            <code className="italic text-sm text-lightblue_vs">
              Bachelor of Global Engineering
            </code>
            <br />
            <code className="text-xs text-brown_vs">• 2023 - 2027</code>
          </div>
        </div>
        <div className="flex flex-row pt-10">
          <div className="w-1/4">
            <code className="text-yellow_vs">Experiences</code>
          </div>
          <div className="w-3/4">
            <code className="text-blue_vs">VP IT - BDB CentraleSupélec</code>
            <br />
            <code className="italic text-sm text-lightblue_vs">
              Gif-sur-Yvette, Île-de-France, France
            </code>
            <br />
            <code className="text-xs text-brown_vs">• Sept 2024 - Present</code>
            <br />
            <code className="text-sm">
              <br />• Developed and maintained the official website (bdbcs.fr).
              <br />• Implemented a platform for online payments and
              liability waivers.
              <br />• Created a membership system for current and upcoming years.
              <br />• Ensured the IT infrastructure was stable and secure.
            </code>
            <br />
            <br />
            <code className="text-blue_vs">Volunteer - Emmaüs Bougival</code>
            <br />
            <code className="italic text-sm text-lightblue_vs">
              Chatou, Île-de-France, France
            </code>
            <br />
            <code className="text-xs text-brown_vs">• Aug 2024 - Present</code>
            <br />
            <code className="text-sm">
              <br />• Supported IT operations for companions.
              <br />• Conducted training sessions to boost digital autonomy.
              <br />• Participated in projects promoting social and
              environmental missions.
            </code>
          </div>
        </div>
        <div className="flex flex-row pt-10 flex-wrap">
          <div className="w-1/4">
            <code className="text-yellow_vs">Skills</code>
          </div>
          <div className="w-3/4">
            <code className="text-sm">
              <br />• Full Stack Web Development
              <br />• IT Project Management
              <br />• Server Administration
            </code>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resume;